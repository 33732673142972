import { APP_ROUTE, APP_TITLE } from "../constants";

export const getPageTitle = (pathname) => {
    // Check for the home page specifically
    if (pathname === "" || pathname === APP_ROUTE.HOME) {
        return APP_TITLE.HOME;
    }

    // Loop through all routes and find a match
    for (const key in APP_ROUTE) {
        if (APP_ROUTE[key] === pathname) {
            return APP_TITLE[key];
        }
    }

    // Default case to handle unknown paths
    let title = pathname.replace("/", "");
    return title.charAt(0).toUpperCase() + title.slice(1);   
}
