const AiFeatures = ({ show }) => {
  return (
    <div
      className={`border-b border-gray-900/10 pb-12 ${show ? '' : 'hidden'}`}
    >
      <h2 className='text-base font-semibold leading-7 text-gray-900'>
        AI Features
      </h2>
      <p className='mt-1 text-sm leading-6 text-gray-600'>
        Which of these AI features are you most interested in?
      </p>

      <div className='mt-10 grid grid-cols-12 gap-x-6 gap-y-8 '>
        <fieldset>
          <div className='mt-2 space-y-2'>
            <div className='relative flex gap-x-3'>
              <div className='flex h-6 items-center'>
                <input
                  id='voiceTracks'
                  name='aiFeatures'
                  type='checkbox'
                  value='Voice Tracks'
                  className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600'
                />
              </div>
              <div className='text-sm leading-6'>
                <label
                  htmlFor='voiceTracks'
                  className='font-medium text-gray-900'
                >
                  AI Voice Tracks
                </label>
              </div>
            </div>
            <div className='relative flex gap-x-3'>
              <div className='flex h-6 items-center'>
                <input
                  id='infoSpeech'
                  name='aiFeatures'
                  type='checkbox'
                  value='Info Speech'
                  className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600'
                />
              </div>
              <div className='text-sm leading-6'>
                <label
                  htmlFor='infoSpeech'
                  className='font-medium text-gray-900'
                >
                  AI Info Speech (news/weather/travel)
                </label>
              </div>
            </div>
            <div className='relative flex gap-x-3'>
              <div className='flex h-6 items-center'>
                <input
                  id='musicScheduling'
                  name='aiFeatures'
                  type='checkbox'
                  value='Music Scheduling'
                  className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600'
                />
              </div>
              <div className='text-sm leading-6'>
                <label
                  htmlFor='musicScheduling'
                  className='font-medium text-gray-900'
                >
                  AI Music Scheduling
                </label>
              </div>
            </div>
            <div className='relative flex gap-x-3'>
              <div className='flex h-6 items-center'>
                <input
                  id='imaging'
                  name='aiFeatures'
                  type='checkbox'
                  value='Imaging'
                  className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600'
                />
              </div>
              <div className='text-sm leading-6'>
                <label htmlFor='imaging' className='font-medium text-gray-900'>
                  AI Imaging
                </label>
              </div>
            </div>

            <div className='relative flex gap-x-3'>
              <div className='flex h-6 items-center'>
                <input
                  id='promoProduction'
                  name='aiFeatures'
                  type='checkbox'
                  value='Promo Production'
                  className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600'
                />
              </div>
              <div className='text-sm leading-6'>
                <label
                  htmlFor='promoProduction'
                  className='font-medium text-gray-900'
                >
                  AI Promo Production
                </label>
              </div>
            </div>
            <div className='relative flex gap-x-3'>
              <div className='flex h-6 items-center'>
                <input
                  id='showprep'
                  name='aiFeatures'
                  type='checkbox'
                  value='Show Prep'
                  className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600'
                />
              </div>
              <div className='text-sm leading-6'>
                <label htmlFor='showprep' className='font-medium text-gray-900'>
                  AI Showprep
                </label>
              </div>
            </div>
            <div className='relative flex gap-x-3'>
              <div className='flex h-6 items-center'>
                <input
                  id='podcastCreation'
                  name='aiFeatures'
                  type='checkbox'
                  value='Podcast Creation'
                  className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600'
                />
              </div>
              <div className='text-sm leading-6'>
                <label
                  htmlFor='podcastCreation'
                  className='font-medium text-gray-900'
                >
                  AI Podcast Creations
                </label>
              </div>
            </div>
            <div className='relative flex gap-x-3'>
              <div className='flex h-6 items-center'>
                <input
                  id='other'
                  name='aiFeatures'
                  type='checkbox'
                  value='Other'
                  className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600'
                />
              </div>
              <div className='text-sm leading-6 flex gap-2'>
                <label htmlFor='other' className='font-medium text-gray-900'>
                  Other
                </label>
                <div className='flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md'>
                  <input
                    type='text'
                    name='otherFeatures'
                    id='otherFeatures'
                    autoComplete='otherFeatures'
                    className='block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6'
                  />
                </div>
              </div>
            </div>
          </div>
        </fieldset>
      </div>
    </div>
  );
};

export default AiFeatures;
