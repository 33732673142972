import { useState } from 'react';

const Budget = ({ show, val }) => {
  const [budgetVal, setBudgetVal] = useState(val);
  return (
    <div
      className={`border-b border-gray-900/10 pb-12 ${show ? '' : 'hidden'}`}
    >
      <h2 className='text-base font-semibold leading-7 text-gray-900'>
        Spending Budget
      </h2>
      <p className='mt-1 text-sm leading-6 text-gray-600'>
        If you get everything you wanted out of AI for your radio station(s),
        how much could you imagine to spend?
      </p>

      <div className='mt-10 gap-x-6 gap-y-8 '>
        <div className='relative mb-6 w-2/3 mx-auto'>
          <label htmlFor='budget' className='sr-only'>
            Labels range
          </label>
          <input
            id='budget'
            name='budget'
            type='range'
            min='0'
            max='10000'
            className='w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700'
            onChange={(event) => {
              setBudgetVal(event.target.value);
            }}
          />
          <span className='text-sm text-gray-500 dark:text-gray-400 absolute start-0 -bottom-6'>
            0€ / Month
          </span>
          {budgetVal && (
            <span className='text-sm text-black-600 font-semibold absolute start-[35%] -bottom-6'>
              {budgetVal}€ / Month
            </span>
          )}
          <span className='text-sm text-gray-500 dark:text-gray-400 absolute end-0 -bottom-6'>
            10,000€ / Month
          </span>
        </div>
        <div className='relative flex gap-x-3'>
          <div className='flex h-6 items-center'>
            <input
              id='budgetDontKnow'
              name='budgetDontKnow'
              type='checkbox'
              className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600'
            />
          </div>
          <div className='text-sm leading-6'>
            <label
              htmlFor='budgetDontKnow'
              className='font-medium text-gray-900'
            >
              Don't Know
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Budget;
