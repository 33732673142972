const BASE_URL = "https://cms.radiostation.ai/api/v2.0/";
// const BASE_URL = "http://127.0.0.1:8000/api/v2.0/";

const get = async (endpoint, headers = {}) => {
	try {
		const response = await fetch(`${BASE_URL}${endpoint}`, {
			method: "GET",
			mode: "cors",
			headers: {
				"Content-Type": "application/json",
				...headers,
			},
		});
		if (!response.ok) {
			throw new Error("Network response was not ok");
		}
		const data = await response.json();
		return data;
	} catch (error) {
		console.error("Failed to fetch data:", error);
		throw error;
	}
};

// General POST request function
const post = async (endpoint, body, headers = {}) => {
	try {
		const response = await fetch(`${BASE_URL}${endpoint}`, {
			method: "POST",
			mode: "cors",
			headers: {
				"Content-Type": "application/json",
				...headers,
			},
			body: JSON.stringify(body),
		});
		if (!response.ok) {
			throw new Error("Network response was not ok");
		}
		const data = await response.json();
		return data;
	} catch (error) {
		console.error("Failed to post data:", error);
		throw error;
	}
};

export const getRadioStations = async (streamingStatus = null) => {
	const data = await get("get-station-list/");
	const stations = data.result.Stations;

	// Filter based on streaming status if provided
	const filteredStations = stations.filter((station) => {
		return streamingStatus !== null ? station.streaming.status === streamingStatus : true;
	});

	return filteredStations;
};

export const getStationMusic = async (station_id, latitude = "all", longitude = "all") => {
	const body = {
		station_id: station_id,
		latitude: latitude,
		longitude: longitude,
	};
	const data = await post("get-music/", body);
	return data.result.playlist;
};
