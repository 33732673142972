const BasicInfo = ({ show }) => {
  return (
    <div
      className={`border-b border-gray-900/10 pb-12 ${show ? '' : 'hidden'}`}
    >
      <h2 className='text-base font-semibold leading-7 text-gray-900'>
        Profile
      </h2>
      <p className='mt-1 text-sm leading-6 text-gray-600'>
        Enter your details below and then answer three short questions to join
        the waitlist.
      </p>

      <div className='mt-10 grid grid-cols-12 gap-x-6 gap-y-8 '>
        <div className='col-span-4'>
          <label
            htmlFor='firstName'
            className='block text-sm font-medium leading-6 text-gray-900'
          >
            First Name
          </label>
          <div className='mt-2'>
            <div className='flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md'>
              <input
                type='text'
                name='firstName'
                id='fistName'
                autoComplete='fistName'
                className='block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6'
              />
            </div>
          </div>
        </div>
        <div className='col-span-4'>
          <label
            htmlFor='lastName'
            className='block text-sm font-medium leading-6 text-gray-900'
          >
            Last Name
          </label>
          <div className='mt-2'>
            <div className='flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md'>
              <input
                type='text'
                name='lastName'
                id='lastName'
                autoComplete='lastName'
                className='block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6'
              />
            </div>
          </div>
        </div>
        <div className='col-span-10'>
          <label
            htmlFor='companyName'
            className='block text-sm font-medium leading-6 text-gray-900'
          >
            Company Name
          </label>
          <div className='mt-2'>
            <div className='flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md'>
              <input
                type='text'
                name='companyName'
                id='companyName'
                autoComplete='companyName'
                className='block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6'
              />
            </div>
          </div>
        </div>

        <div className='col-span-10'>
          <label
            htmlFor='country'
            className='block text-sm font-medium leading-6 text-gray-900'
          >
            Country
          </label>
          <div className='mt-2'>
            <div className='flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md'>
              <input
                type='text'
                name='country'
                id='country'
                autoComplete='country'
                className='block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6'
              />
            </div>
          </div>
        </div>

        <div className='col-span-10'>
          <label
            htmlFor='email'
            className='block text-sm font-medium leading-6 text-gray-900'
          >
            Email Address
          </label>
          <div className='mt-2'>
            <div className='flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md'>
              <input
                type='email'
                name='email'
                id='email'
                autoComplete='email'
                className='block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6'
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasicInfo;
