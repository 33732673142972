
import { initializeApp } from 'firebase/app';
import {
    getFirestore,
    collection,
    getDocs,
    orderBy,
    query,
} from 'firebase/firestore/lite';
import firebaseConfig from './../config/firebaseConfig';
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

// Get a list of docs from your database
export const getProducts = async (list, orderByKey) => {
    const itemsCol = collection(db, list);
    const itemSnapshot = await getDocs(query(itemsCol, orderBy(orderByKey)));
    const itemList = itemSnapshot.docs.map(doc => doc.data());
    return itemList;
}