import { useEffect, useRef, useState } from "react";
import AiFeatures from "./AiFeatures";
import BasicInfo from "./BasicInfo";
import Budget from "./Budget";
import CurrentRole from "./CurrentRole";
import ThankYou from "./ThankYou";
import ErrorTab from "./ErrorTab";

const GET_FORM_ID = "paovxdzb";

const MultiStepForm = () => {
	const [step, setStep] = useState(1);
	const [showModal, setShowModal] = useState(false);
	const formRef = useRef();

	const handleBack = () => {
		setStep(step - 1);
		setError(null);
	};

	const handleNext = () => {
		const { formValues: values } = updateValues();
		setError(null);
		if (performChecks(step, values)) {
			setStep(step + 1);
		}
	};
	const [formValues, setFormValues] = useState(null);
	const [error, setError] = useState(null);

	const handleSubmit = (data) => {
		fetch("https://getform.io/f/" + GET_FORM_ID, {
			method: "POST",
			body: data,
			headers: {
				Accept: "application/json",
			},
		})
			.then((response) => console.log(response))
			.catch((error) => console.log(error));
	};

	const performChecks = (currentStep, values) => {
		const requiredFields = {
			1: {
				keys: [["firstName", "lastName", "companyName", "country", "email"]],
				message: "All fields are required.",
			},
			2: {
				keys: [["currentRoles"], ["otherRole"]],
				message: "At least one role must be selected",
			},
			3: {
				keys: [["aiFeatures"], ["otherFeatures"]],
				message: "At least one feature must be selected",
			},
		};
		const required = requiredFields[currentStep];
		if (!required) {
			setError(null);
			return true;
		}

		let passed = false;

		required.keys.forEach((keys) => {
			const haveValues = keys.filter((keyToCheck) => !!values[keyToCheck].length);
			if (haveValues.length === keys.length) {
				passed = true;
			}
		});

		if (!passed) {
			setError(required.message);
		}

		return passed;
	};

	const updateValues = () => {
		const formData = new FormData(formRef.current);
		const values = {};
		const currentRoles = Array.from(formData.getAll("currentRole"));
		const aiFeatures = Array.from(formData.getAll("aiFeatures"));

		formData.forEach((value, key) => {
			if (!["currentRole", "aiFeatures"].includes(key)) {
				values[key] = value;
			}
		});
		values.currentRoles = currentRoles;
		values.aiFeatures = aiFeatures;
		setFormValues(values);

		formData.append("currentRole", currentRoles);
		formData.append("aiFeatures", aiFeatures);
		return { formData, formValues: values };
	};

	useEffect(() => {
		if (step === 5) {
			const { formData } = updateValues();
			handleSubmit(formData);
		}
	}, [step]);

	return (
		<div>
			<button
				onClick={() => {
					setShowModal(!showModal);
				}}
				className={`fixed top-[40%] right-0 z-[50] px-8 py-4 bg-gradient-to-r from-blue-500 to-purple-500 text-white font-bold rounded-full transition-transform transform-gpu hover:-translate-y-1 hover:shadow-lg'`}
			>
				Get on Waitlist
			</button>

			<div
				className={`${
					showModal ? "scale-100 translate-x-0 opacity-[1]" : "scale-0 translate-x-full opacity-[0]"
				} bg-black/40 transition duration-700 h-screen w-screen fixed top-0 right-0 z-50 justify-center items-center md:inset-0 h-[calc(100%-1rem)] flex items-center justify-center`}
				onClick={() => {
					setShowModal(false);
				}}
			>
				<div
					id="default-modal"
					tabIndex="-1"
					aria-hidden="true"
					className={"h-fit w-[600px] m-auto bg-white rounded-lg overflow-y-auto overflow-x-hidden"}
					onClick={(event) => {
						event.stopPropagation();
					}}
				>
					<div className="relative p-4 pb-0 w-full max-w-2xl max-h-full">
						{error && <ErrorTab message={error} />}
						<div className="relative">
							<form
								ref={formRef}
								onSubmit={(event) => {
									event.preventDefault();
									const formData = new FormData(event.target);
									const currentRoles = Array.from(formData.getAll("currentRole"));
									const otherRole = formData.get("otherRole");
									console.log(currentRoles);
									console.log(otherRole);
								}}
							>
								<BasicInfo show={step === 1} values={formValues} />
								<CurrentRole show={step === 2} />
								<AiFeatures show={step === 3} />
								<Budget show={step === 4} val={formValues?.budget} />
								<ThankYou show={step === 5} />
							</form>
						</div>

						<div className="w-2/3 mx-auto bg-gray-200 rounded-full h-fit dark:bg-gray-700 mb-4 relative text-center">
							<div className={`bg-blue-600 rounded-full absolute top-0 left-0 h-full z-[2]`} style={{ width: (step - 1) * 25 + "%" }}></div>
							<span className="z-10 relative text-white">{(step - 1) * 25}%</span>
						</div>

						<div className="flex gap-2 mx-auto w-fit">
							{step > 1 && (
								<button
									onClick={handleBack}
									type="button"
									className="focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900"
								>
									Back
								</button>
							)}
							{step === 5 && (
								<button
									onClick={() => {
										setShowModal(false);
									}}
									className="focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900"
								>
									Close
								</button>
							)}
							{step < 5 && (
								<button
									onClick={handleNext}
									type={"button"}
									className="focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900"
								>
									Next
								</button>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default MultiStepForm;
