import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setProducts } from "../store/appSlice";
import { getRadioStations } from "../services/cms.service";
import LiveItemList from "../components/LiveItemList";

const LiveRadio = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		getRadioStations(true).then((data) => {
			dispatch(setProducts({ type: "liveRadios", data }));
		});
		return () => {};
	}, [dispatch]);

	return (
		<>
			<LiveItemList />
		</>
	);
};

export default LiveRadio;
