import Base from "./app/Base";
import Header from "./components/Header";
// import Player from "./components/Player";

const App = () => {
	return (
		<>
			<Header />
			<Base />
			{/* <Player /> */}
		</>
	);
};

export default App;
