import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setProducts } from '../store/appSlice';
import { getRadioStations } from '../services/cms.service';
import PlaylistItemList from '../components/PlaylistItemList';
import { useSelector } from 'react-redux';
import AudioPlayer from '../components/AudioPlayer';
import { cleanAiRadio } from '../store/appSlice';

const AI_RADIO = () => {
  const { globalPlayer } = useSelector((state) => state.appStore);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(cleanAiRadio());
  }, []);

  useEffect(() => {
    getRadioStations(false).then((data) => {
      dispatch(setProducts({ type: 'aiRadios', data }));
    });
    return () => {};
  }, [dispatch]);

  return (
    <div className="relative">
      <PlaylistItemList />
      {globalPlayer ? <AudioPlayer /> : <></>}
    </div>
  );
};

export default AI_RADIO;
