import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Bars3BottomLeftIcon, FolderIcon, RadioIcon, SparklesIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setSearchText } from "../store/appSlice";
import { APP_ROUTE, APP_TITLE } from "../constants";
import { getPageTitle } from "../utils/general";
import routes from "../routes";
import Footer from "../components/Footer";
import Curve from "../assets/images/curve.png";
import Arrow from "../assets/images/arrowcircle_right.png";
import Logo from "../assets/images/home.svg";
import Reveal from "../shared/Reveal";

const Base = (props) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const [pageTitle, setPageTitle] = useState("");
	const { searchText } = useSelector((state) => state.appStore);
	const [navBarOpen, setNavBarOpen] = useState(false);
	const [navigation, setNavigation] = useState([]);
	// const userNavigation = [
	//   { name: 'Your Profile', href: '#' },
	//   { name: 'Settings', href: '#' },
	//   { name: 'Sign out', href: '#' },
	// ]
	useEffect(() => {
		dispatch(setSearchText(""));
		setPageTitle(getPageTitle(location.pathname));
		setNavigation([
			{
				name: APP_TITLE.LIVE_RADIO,
				href: APP_ROUTE.LIVE_RADIO,
				icon: RadioIcon,
				current: APP_ROUTE.LIVE_RADIO === location.pathname,
			},
			{
				name: APP_TITLE.AI_RADIO,
				href: APP_ROUTE.AI_RADIO,
				icon: SparklesIcon,
				current: APP_ROUTE.AI_RADIO === location.pathname,
			},
			{
				name: APP_TITLE.PODCASTS,
				href: APP_ROUTE.PODCASTS,
				icon: FolderIcon,
				current: APP_ROUTE.PODCASTS === location.pathname,
			},
		]);
		return () => {};
	}, [dispatch, location.pathname, setNavigation, setPageTitle]);

	const classNames = (...classes) => {
		return classes.filter(Boolean).join(" ");
	};

	const [sidebarOpen, setSidebarOpen] = useState(false);

	const handleClick = (item) => {
		navigate(item.href);
	};

	const handleSearch = (value) => {
		dispatch(setSearchText(value));
	};

	return (
		<>
			<div className="">
				<Transition.Root show={sidebarOpen} as={Fragment}>
					<Dialog as="div" className="relative z-40 md:hidden" onClose={setSidebarOpen}>
						<Transition.Child
							as={Fragment}
							enter="transition-opacity ease-linear duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="transition-opacity ease-linear duration-300"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
						</Transition.Child>

						<div className="fixed inset-0 z-40 flex">
							<Transition.Child
								as={Fragment}
								enter="transition ease-in-out duration-300 transform"
								enterFrom="-translate-x-full"
								enterTo="translate-x-0"
								leave="transition ease-in-out duration-300 transform"
								leaveFrom="translate-x-0"
								leaveTo="-translate-x-full"
							>
								<Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-gray-800 pt-5 pb-4">
									<Transition.Child
										as={Fragment}
										enter="ease-in-out duration-300"
										enterFrom="opacity-0"
										enterTo="opacity-100"
										leave="ease-in-out duration-300"
										leaveFrom="opacity-100"
										leaveTo="opacity-0"
									>
										<div className="absolute top-0 right-0 -mr-12 pt-2">
											<button
												type="button"
												className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
												onClick={() => setSidebarOpen(false)}
											>
												<span className="sr-only">Close sidebar</span>
												<XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
											</button>
										</div>
									</Transition.Child>
									<div className="flex flex-shrink-0 items-center px-4">
										<img className="h-8 w-auto logo" src={Logo} alt="WAVEM" onClick={() => navigate("/")} />
									</div>
									<div className="mt-5 h-0 flex-1 overflow-y-auto">
										<nav className="space-y-1 px-2">
											{navigation.map((item) => (
												<div
													key={item.name}
													className={classNames(
														item.current ? "bg-gray-900 text-white" : "text-gray-300 hover:bg-gray-700 hover:text-white",
														"group flex items-center px-2 py-2 text-base font-medium rounded-md",
														"pointer",
													)}
													onClick={() => handleClick(item)}
												>
													<item.icon
														className={classNames(
															item.current ? "text-gray-300" : "text-gray-400 group-hover:text-gray-300",
															"mr-4 flex-shrink-0 h-6 w-6",
														)}
													/>
													{item.name}
												</div>
											))}
										</nav>
									</div>
								</Dialog.Panel>
							</Transition.Child>
							<div className="w-14 flex-shrink-0" aria-hidden="true">
								{/* Dummy element to force sidebar to shrink to fit close icon */}
							</div>
						</div>
					</Dialog>
				</Transition.Root>

				<div className={`md:grid transition duration-200 ${navBarOpen ? "md:grid-cols-[16rem_1fr]" : "md:grid-cols-[6rem_1fr]"}`}>
					{/* Static sidebar for desktop */}
					<div className="w-full h-full relative">
						<Reveal hidden={{ opacity: 0, scale: 0 }} visible={{ opacity: 1, scale: 1 }} width="full">
							<img
								src={Arrow}
								alt="Arrow"
								className={`absolute right-3 h-6 w-6 top-5 z-10 pointer transition duration-500 delay-50 ${
									navBarOpen ? "transform rotate-180" : ""
								}`}
								onClick={() => setNavBarOpen(!navBarOpen)}
							/>
						</Reveal>
						<div
							className={`hidden md:sticky top-0 w-full min-h-screen md:inset-y-0 transition duration-200 md:flex ${
								navBarOpen ? "md:w-64" : "md:w-[6rem]"
							} md:flex-col`}
						>
							{/* Sidebar component, swap this element with another sidebar if you like */}
							<div className="flex min-h-0 flex-1 flex-col bg-gray-800">
								<Reveal hidden={{ opacity: 0, x: -100 }} visible={{ opacity: 1, x: 1 }} width="full">
									<div
										className="flex h-16 flex-shrink-0 items-center bg-gray-900 px-4 group gap-3 py-2 text-sm font-medium pointer text-gray-300"
										onClick={() => navigate("/")}
									>
										<svg
											className="aspect-square w-6 logo text-gray-400 hover:text-white"
											aria-hidden="true"
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 20 20"
										>
											<path
												stroke="currentColor"
												strokeLinecap="round"
												strokeLinejoin="round"
												strokeWidth="1.3"
												d="M3 8v10a1 1 0 0 0 1 1h4v-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v5h4a1 1 0 0 0 1-1V8M1 10l9-9 9 9"
											/>
										</svg>
										{navBarOpen && "Home"}
									</div>
								</Reveal>
								<div className="flex flex-1 flex-col overflow-y-auto">
									<nav className="flex-1 space-y-1 px-2 py-4">
										{navigation.map((item) => (
											<section key={item.name}>
												<Reveal hidden={{ opacity: 0, x: -100 }} visible={{ opacity: 1, x: 1 }} width="full">
													<div
														className={classNames(
															item.current ? "bg-gray-900 text-white" : "text-gray-300 hover:bg-gray-700 hover:text-white",
															"group flex items-center px-2 py-2 text-sm font-medium rounded-md",
															"pointer",
														)}
														onClick={() => handleClick(item)}
													>
														<item.icon
															className={classNames(
																item.current ? "text-gray-300" : "text-gray-400 group-hover:text-gray-300",
																"mr-3 flex-shrink-0 h-6 w-6",
															)}
														/>
														{navBarOpen && item.name}
													</div>
												</Reveal>
											</section>
										))}
									</nav>
								</div>
							</div>
						</div>
					</div>
					<div className="flex flex-col min-h-screen">
						<div className="sticky top-0 z-10 flex h-16 flex-shrink-0 bg-white shadow">
							<button
								type="button"
								className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
								onClick={() => setSidebarOpen(true)}
							>
								<span className="sr-only">Open sidebar</span>
								<Bars3BottomLeftIcon className="h-6 w-6" aria-hidden="true" />
							</button>
							<div className="flex flex-1 justify-between ">
								<div className="flex flex-1">
									<form className="flex w-full md:ml-0" action="#" method="GET">
										<label htmlFor="search-field" className="sr-only">
											Search
										</label>
										<div className="relative w-full text-gray-400 focus-within:text-gray-600">
											<div className="pointer-events-none absolute inset-y-0 left-4 flex items-center">
												<MagnifyingGlassIcon className="h-5 w-5" aria-hidden="true" />
											</div>
											<input
												id="search-field"
												className="block h-full w-full border-transparent py-2 pl-12 pr-7 text-gray-900 placeholder-gray-500 focus:border-transparent focus:placeholder-gray-400 focus:outline-none focus:ring-0 sm:text-sm"
												placeholder="Search"
												type="search"
												name="search"
												value={searchText}
												onChange={(e) => handleSearch(e.target.value)}
											/>
										</div>
									</form>
								</div>
								{/* <div className="ml-4 flex items-center md:ml-6">
                </div> */}
							</div>
						</div>

						<main className="flex-1">
							<div className="py-6">
								<div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
									<h1 className="text-2xl font-bold text-gray-900 flex items-center gap-1">
										<Reveal hidden={{ opacity: 0, scale: 0 }} visible={{ opacity: 1, scale: 1 }}>
											<img src={Curve} alt="Curve" loading="lazy" className="h-5" />
										</Reveal>
										{pageTitle}
									</h1>
								</div>

								<div className="">
									{/* Replace with your content */}
									<Routes>
										{routes?.map((item, key) => {
											return <Route exact name={item.name} path={item.route} element={item.component} key={key} />;
										})}
										<Route path="*" element={<Navigate to="/live" />} />
									</Routes>
									{/* /End replace */}
								</div>
							</div>
						</main>
						<Footer />
					</div>
				</div>
			</div>
		</>
	);
};

export default Base;
